import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProviderWraper } from "./theme/providers/ThemeProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense fallback="Loading">
      <ThemeProviderWraper>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProviderWraper>
    </Suspense>
  </BrowserRouter>
);
