import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { _AuthApi } from "api/auth";
import ButtonLoader from "components/shared/ButtonLoader";
import { BoxStyled } from "components/styled/BoxStyled";
import { TextFieldStyled } from "components/styled/TextField";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoginBg from "theme/LoginBg";
import * as yup from "yup";
let schema = yup.object().shape({
  email: yup
    .string()
    .email("This must be a valid email")
    .required("Email is required"),
});
const ResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);

    _AuthApi
      .resetPass({ email })
      .then((res) => {
        setLoading(true);
        navigate(`/reset-password/check-code/${email}`, { replace: true });
      })
      ?.catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <LoginBg>
      <Button
        disableOnLoading
        loading={false}
        fullWidth
        type="submit"
        variant="contained"
        sx={{
          width: 130,
          alignItems: "center",
          backgroundColor: "origin.main",
          textAlign: "center",
          ml: "50px",
          mt: "10px",

          "&:hover": {
            backgroundColor: "origin.main",
            transform: "scale(1.1)",
          },
        }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          flexDirection: "column",
        }}
      >
        <BoxStyled
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
            background: "#878d8b",
            mixBlendMode: "luminosity",
            boxShadow: "0px 0px 24px 1px rgb(102 165 171)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: "120px" }}></Box>
            <Typography variant="h5" sx={{ color: "white", mt: "10px" }}>
              Reset Your Password
            </Typography>
            <Typography variant="h6" sx={{ color: "white", mt: "10px" }}>
              Enter your credentials to continue
            </Typography>
          </Grid>
          <Box
            sx={{ width: "100%", mt: "30px" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <Typography variant="inputTitle">Email</Typography>
            </Box>
            <TextFieldStyled
              type="email"
              sx={{
                width: "100%",
                "& .MuiInputBase-input": { color: "white" },
              }}
              placeholder="Enter your email"
              {...register("email", { validate: true })}
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.email.message}
              </span>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                my: "10px",
              }}
            >
              {loading ? (
                <ButtonLoader
                  disableOnLoading
                  loading={true}
                  disabled={loading}
                  fullWidth
                >
                  Waiting..
                </ButtonLoader>
              ) : (
                <Button
                  sx={{
                    display: "block",
                    width: 300,
                    backgroundColor: "origin.main",
                    margin: "30px auto 0 auto",
                    "&:hover": {
                      backgroundColor: "#a1eaef",
                      transform: "scale(1.1)",
                    },
                  }}
                  disabled={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  Send Verification Code
                </Button>
              )}
            </Box>
          </Box>
        </BoxStyled>
      </Box>
    </LoginBg>
  );
};

export default ResetPassword;
